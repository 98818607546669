import { ref } from "@vue/composition-api";
export default function useTable(getTableData) {
    const tableParams = ref({
        page: 1,
        perPage: 30
    });
    const isLoading = ref(false);
    const filter = ref({});
    const tableData = ref([]);
    const tablePagination = ref({
        total: 0
    });

    // ============================ Table Functions ============================
    function updateParams(newProps) {
        tableParams.value = Object.assign({}, tableParams, newProps);
    }

    function onPageChange(params) {
        updateParams({ page: params.currentPage });
        getTableData();
    }

    function onPerPageChange(params) {
        updateParams({ perPage: params.currentPerPage });
        getTableData();
    }

    return {
        isLoading,
        tableParams,
        tableData,
        tablePagination,
        filter,
        updateParams,
        onPageChange,
        onPerPageChange
    };
}
