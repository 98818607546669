<template>
  <div class="acn-job-applications">
    <div class="container fluid">
      <h1 class="m-4">My Job Applications</h1>

      <vue-good-table
        class="card"
        mode="remote"
        @on-page-change="onPageChange"
        @on-per-page-change="onPerPageChange"
        :columns="tableColumns"
        :rows="tableData"
        :totalRows="tablePagination.total"
        :pagination-options="{
          enabled: true,
          mode: 'pages',
          perPage: 30,
          perPageDropdown: perPageOptions,
          dropdownAllowAll: false
        }"
        :sort-options="{
          enabled: false,
          initialSortBy: { field: 'createdAt', type: 'desc' }
        }"
      >
        <div slot="emptystate">
          <no-data message="There is no pending job application."></no-data>
        </div>
        <!-- Table Buttons -->
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'actions'">
            <fd-button
              class="bordered danger mr-1"
              @click="withdrawApplication(props.row.id)"
            >
              Withdraw
            </fd-button>
          </span>
          <span v-if="props.column.field == 'property'">
            <PropertySummary
              :property="props.row.acnGroup.acnQuest.listing.property"
              address
              :purpose="false"
            >
            </PropertySummary>
          </span>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </vue-good-table>
    </div>
  </div>
</template>

<script>
import vueGoodTable from "@/mixins/vue-good-table/mixin";
import useTable from "@/modules/General/composables/useTable";
import { acn as acnAPI } from "@/api";
import { unref } from "@vue/composition-api";

export default {
  setup(props, { root }) {
    const {
      isLoading,
      tableParams,
      tableData,
      tablePagination,
      filter,
      updateParams,
      onPageChange,
      onPerPageChange
    } = useTable(getMyJobApplications);

    async function getMyJobApplications() {
      try {
        isLoading.value = true;
        let filteredParam = root.$cleanQueryParam(filter.value);

        let res = await acnAPI.getMyJobApplications({
          queries: filteredParam,
          page: unref(tableParams.page),
          perPage: unref(tableParams.perPage)
        });

        tableData.value = root._.cloneDeep(res.data);
        tablePagination.value = root._.cloneDeep(res.meta.pagination);

        isLoading.value = false;
      } catch (error) {
        isLoading.value = false;
        root.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to fetch job application data. Please try again later."
        });
      }
    }

    return {
      isLoading,
      tableParams,
      tableData,
      tablePagination,
      filter,
      updateParams,
      onPageChange,
      onPerPageChange,
      getMyJobApplications
    };
  },
  components: {
    PropertySummary: () =>
      import("@/components/GlobalComponents/Shared/Property/PropertySummary")
  },
  mixins: [vueGoodTable],
  props: {},
  data: function () {
    return {
      tableColumns: [
        {
          label: "",
          field: "actions",
          width: "150px",
          tdClass: "text-center"
        },
        {
          label: "Property",
          field: "property"
        },
        {
          label: "Job",
          field: (row) =>
            row.acnReward.isForSpecialist
              ? "Specialist"
              : row.acnReward.acnScope
        },
        {
          label: "Date",
          field: "createdAt",
          formatFn: (val) => this.$dayjs(val).format("DD-MM-YYYY")
        }
      ]
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.init();
  },
  methods: {
    init() {
      this.getMyJobApplications();
    },
    async withdrawApplication(applicationId) {
      let confirm = await this.$confirm({
        type: "alert",
        title: "Withdraw Job Application",
        message: "Are you sure you want to remove this job application?",
        confirmText: "Withdraw"
      });

      if (confirm) {
        try {
          this.$store.commit("setIsLoading", true);
          await acnAPI.withdrawJobApplication(applicationId);

          this.$store.commit("setIsLoading", false);
          this.$notify({
            group: "alert",
            type: "success",
            title: "Success",
            text: "You have been withdrawn from job applications"
          });
          this.getMyJobApplications();
        } catch (error) {
          this.$store.commit("setIsLoading", false);
          this.$notify({
            group: "alert",
            type: "error",
            title: "Error",
            text: "Failed to withdraw job application. Please try again later."
          });
        }
      }
    }
  }
};
</script>

<style lang="scss"></style>
