var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"acn-job-applications"},[_c('div',{staticClass:"container fluid"},[_c('h1',{staticClass:"m-4"},[_vm._v("My Job Applications")]),_c('vue-good-table',{staticClass:"card",attrs:{"mode":"remote","columns":_vm.tableColumns,"rows":_vm.tableData,"totalRows":_vm.tablePagination.total,"pagination-options":{
        enabled: true,
        mode: 'pages',
        perPage: 30,
        perPageDropdown: _vm.perPageOptions,
        dropdownAllowAll: false
      },"sort-options":{
        enabled: false,
        initialSortBy: { field: 'createdAt', type: 'desc' }
      }},on:{"on-page-change":_vm.onPageChange,"on-per-page-change":_vm.onPerPageChange},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'actions')?_c('span',[_c('fd-button',{staticClass:"bordered danger mr-1",on:{"click":function($event){return _vm.withdrawApplication(props.row.id)}}},[_vm._v(" Withdraw ")])],1):_vm._e(),(props.column.field == 'property')?_c('span',[_c('PropertySummary',{attrs:{"property":props.row.acnGroup.acnQuest.listing.property,"address":"","purpose":false}})],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])},[_c('div',{attrs:{"slot":"emptystate"},slot:"emptystate"},[_c('no-data',{attrs:{"message":"There is no pending job application."}})],1)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }